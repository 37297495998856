
/**
 * @name: 商城管理-新闻资讯
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-新闻资讯
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  newsPageApi,
  newsUpdateApi,
  newsDetailApi,
  newsCreateApi,
  newsUpdateTopApi
} from '@/apis/mall/news'
import {
  INews,
  INewsParams
} from '@/apis/mall/news/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from "@/utils/common";
import moment from 'moment'

@Component({})
export default class mallNews extends Vue {
  moment = moment
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: INews[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: INewsParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<INews> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menuWidth: 100,
    viewTitle: '详情',
    dialogWidth: '800px',
    column: [
      {
        label: '序号',
        type: 'index',
        width: 50,
        align: 'center',
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: '标题',
        prop: 'title',
        align: 'center',
        slot: true,
        span: 24,
        search: true,
        maxlength: 50,
        overHidden: true,
        rules: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '发布日期',
        prop: 'releaseTime',
        width: 120,
        align: 'center',
        search: true,
        span: 24,
        type: 'daterange',
        slot: true,
        rules: [
        {
            required: true,
            message: '请选择发布日期',
            trigger: 'blur'
          }
        ],
        searchSlot: true,
        viewSlot: true,
        addSlot: true,
        editSlot: true
      },
      {
        label: '封面图',
        prop: 'coverPic',
        align: 'center',
        span: 24,
        type: 'image',
        imgSuffix: ';',
        width: 130,
        rules: [
          {
            required: true,
            message: '请上传封面图',
            trigger: 'blur'
          }
        ],
        editSlot: true,
        addSlot: true
      },
      {
        label: '状态',
        prop: 'status',
        align: 'center',
        type: 'select',
        addHide: true,
        editHide: true,
        viewHide: true,
        search: true,
        width: 100,
        slot: true,
        dicData: [
          {
            label: '展示',
            value: 1
          },
          {
            label: '不展示',
            value: 2
          }
        ]
      },
      {
        label: '置顶轮播',
        prop: 'isTopping',
        align: 'center',
        slot: true,
        type: 'select',
        width: 100,
        search: true,
        viewHide: true,
        editHide: true,
        addHide: true,
        dicData: [
          {
            label: '是',
            value: 1
          },
          {
            label: '否',
            value: 2
          }
        ]
      },
      {
        label: '创建时间',
        prop: 'addTime',
        align: 'center',
        width: 180,
        addHide: true,
        editHide: true,
        viewHide: true,
        search: true,
        type: 'daterange'
      },
      {
        label: '资讯详情',
        prop: 'coverDtl',
        hide: true,
        viewSlot: true,
        editSlot: true,
        addSlot: true,
        rules: [
          {
            required: true,
            message: '请输入资讯详情',
            trigger: 'blur'
          }
        ]
      }
    ]
  }

  getList () {
    this.tableLoading = true;

    const query: INewsParams = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addTimeStart = query.addTime[0]
      query.addTimeEnd = query.addTime[1]
    } else {
      query.addTimeStart = ''
      query.addTimeEnd = ''
    }
    delete query.addTime
    if (query.releaseTime && query.releaseTime.length) {
      query.releaseTimeStart = query.releaseTime[0]
      query.releaseTimeEnd = query.releaseTime[1]
    } else {
      query.releaseTimeStart = ''
      query.releaseTimeEnd = ''
    }
    delete query.releaseTime

    newsPageApi(query).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  updateStatus (obj: Partial<INews>) {
    newsUpdateApi(obj).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  updateTopStatus (id: string) {
    newsUpdateTopApi(id).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  /**
   * 打开详情
   * @param row
   * @param index
   */
  openView (row: INews, index: number) {
    newsDetailApi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowView(e, index)
    })
  }

  openAdd () {
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
    this.$set(this.modelForm, 'releaseTime', '')
  }

  beforeUpload (file: File) {
    const fileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    const fileSize = file.size / 1024 < 500;

    if (!fileType) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!fileSize) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
    return fileType && fileSize;
  }

  openEdit (row: INews, index: number) {
    newsDetailApi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
    })
  }

  rowSave (form: INews, done: Function, loading: Function) {
    newsCreateApi(form).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: INews, done: Function, loading: Function) {
    newsUpdateApi(form).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  created () {
    this.getList()
  }
}
