/**
 * @name: 商城管理-新闻资讯接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-新闻资讯接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { INews, INewsParams } from "./types";

export const newsPageApi = (params: INewsParams) => get<IPageRes<INews[]>>("/admin/newInfo/query", params)

export const newsUpdateApi = (data: Partial<INews>) => postJ("/admin/newInfo/modify", data)

export const newsDetailApi = (id: string) => get<INews>('/admin/newInfo/detail/' + id)

export const newsCreateApi = (data: Partial<INews>) => postJ("/admin/newInfo/create", data)

export const newsUpdateTopApi = (id: string) => postJ('/admin/newInfo/modify/topCarousel', {id})
